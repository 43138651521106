import { LOADING_TRUE, LOADING_FALSE, LOADING_RESET } from "@/core/services/store/store";
import store from "@/core/services/store/store";

const { state, mutations } = store;

export const LOADING_NONE = "loadingNone";

const loading = {
  on(style) {
    mutations[LOADING_TRUE](state, style);
  },
  off({ delay }) {
    mutations[LOADING_FALSE](state, delay);
  },
  reset() {
    mutations[LOADING_RESET](state);
  },
};

function defaultLoading(loadingSetting) {
  const defaultLoading = {};

  if (loadingSetting === LOADING_NONE) {
    defaultLoading.on = () => {};
    defaultLoading.off = () => {};
  } else {
    const { style, delay } = loadingSetting;
    defaultLoading.on = () => {
      mutations[LOADING_TRUE](state, style);
    };
    defaultLoading.off = () => {
      mutations[LOADING_FALSE](state, delay ? delay : 500);
    };
  }

  return defaultLoading;
}

export { loading, defaultLoading };
