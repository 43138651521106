import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import { defaultLoading } from "../helpers/loadingHandling";
import { loadingConfig } from "../../utils/loadingConfig";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = "https://app.ddverp.com/meeting-api/v1/";
    // Vue.axios.defaults.baseURL = "https://test.didongviet.vn/ddv-meeting/v1/";
    // Vue.axios.defaults.baseURL = "http://localhost:4005/";
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */

  get(resource, slug = "") {
    const loading = defaultLoading(loadingConfig.get[resource] || {});
    loading.on();
    return Vue.axios
      .get(`${resource}/${slug}`)
      .then((data) => {
        loading.off();
        return data;
      })
      .catch((error) => {
        loading.off();
        throw error;
        // throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    const loading = defaultLoading(loadingConfig.post[resource] || {});
    loading.on();
    return Vue.axios
      .post(`${resource}`, params)
      .then((data) => {
        loading.off();
        return data;
      })
      .catch((error) => {
        loading.off();
        throw error;
        // throw new Error(`[RWV] ApiService ${error}`);
      });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    const loading = defaultLoading(loadingConfig.put[resource] || {});
    loading.on();
    return Vue.axios
      .put(`${resource}`, params)
      .then((data) => {
        loading.off();
        return data;
      })
      .catch((error) => {
        loading.off();
        throw error;
        // throw new Error(`[RWV] ApiService ${error}`);
      });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
