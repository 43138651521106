<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import ApiService from "@/core/services/api.service";
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { SET_DEPARTMENT_LIST } from "@/core/services/store/store";

export default {
  name: "MetronicVue",
  async created() {
    const { data } = await ApiService.get("/department/getList");
    this.$store.dispatch(SET_DEPARTMENT_LIST, data.data);
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
};
</script>
