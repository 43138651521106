import { parseJwt } from "../jwt.service";

const checkAdminOrClient = (to, from, next) => {
  const token = localStorage.getItem("id_token");
  const objUser = parseJwt(token);
  if (objUser.role !== "admin") {
    next("/");
  } else {
    next();
  }
};

export { checkAdminOrClient };
