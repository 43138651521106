import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { BToast } from "bootstrap-vue";
import moment from "moment";
import ENUM from "./enum";
import SaveDataUtil from "./saveDataToLocal";
export const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
  masked: false,
});

export const unMaskPrice = (price) => {
  if (!price) return 0;
  return price
    .toString()
    .split(",")
    .join("");
};

export const unMaskMoney = (price) => {
  if (!price) return 0;
  return price.toString().replace(/[~`!@#$%^&*()+={}[\];:'"<>.,/\\?-_]/g, "");
};

export const removeAccents = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
};

export const convertNumberToText = (number) => {
  if (number == 0) return " " + numbers[0];

  var result = "",
    suffix = "";
  do {
    let million = number % 1000000000;
    number = Math.floor(number / 1000000000);
    if (number > 0) {
      result = readMillion(million, true) + suffix + result;
    } else {
      result = readMillion(million, false) + suffix + result;
    }
    suffix = " tỷ";
  } while (number > 0);
  return result;
};

var numbers = ["không", "một", "hai", "ba", "bốn", "năm", "sáu", "bảy", "tám", "chín"];

function readDozens(number, check) {
  var result = "";
  let dozen = Math.floor(number / 10);
  let unit = number % 10;
  if (dozen > 1) {
    result = " " + numbers[dozen] + " mươi";
    if (unit == 1) {
      result += " mốt";
    }
  } else if (dozen == 1) {
    result = " mười";
    if (unit == 1) {
      result += " một";
    }
  } else if (check && unit > 0) {
    result = " lẻ";
  }
  if (unit == 5 && dozen > 1) {
    result += " lăm";
  } else if (unit > 1 || (unit == 1 && dozen == 0)) {
    result += " " + numbers[unit];
  }
  return result;
}

function readBlock(number, check) {
  var result = "";
  let hundred = Math.floor(number / 100);
  number = number % 100;
  if (check || hundred > 0) {
    result = " " + numbers[hundred] + " trăm";
    result += readDozens(number, true);
  } else {
    result = readDozens(number, false);
  }
  return result;
}

function readMillion(number, check) {
  var result = "";
  let million = Math.floor(number / 1000000);
  number = number % 1000000;
  if (million > 0) {
    result = readBlock(million, check) + " triệu";
    check = true;
  }
  let thousand = Math.floor(number / 1000);
  number = number % 1000;
  if (thousand > 0) {
    result += readBlock(thousand, check) + " nghìn";
    check = true;
  }
  if (number > 0) {
    result += readBlock(number, check);
  }
  return result;
}

export const xoa_dau = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  return str;
};
export function makeToastSuccess(message) {
  const bsToaster = new BToast();
  bsToaster.$bvToast.toast(message, {
    title: `Thông báo`,
    variant: "success",
    solid: true,
  });
}

export function makeToastFaile(message) {
  const bsToaster = new BToast();
  bsToaster.$bvToast.toast(message, {
    title: `Thông báo`,
    variant: "danger",
    solid: true,
  });
}

export function makeToastFailHtml(vm, messages) {
  const h = vm.$createElement;
  const errorCreate = messages.reduce((result, item) => {
    result.push(h("span", { class: ["d-block"] }, item));
    return result;
  }, []);
  const vNodesTitle = h("div", { class: [] }, [...errorCreate]);
  const bsToaster = new BToast();
  bsToaster.$bvToast.toast(vNodesTitle, {
    title: `Thông báo`,
    variant: "danger",
    solid: true,
  });
}

export function formatDate(dateIn, format) {
  const dateOut = dateIn
    ? moment(dateIn).format(format ? format : "DD/MM/yyyy HH:mm:ss")
    : null;
  return dateOut;
}

export function convertPrice(number) {
  return number ? new Intl.NumberFormat("vn-VN").format(number) : 0;
}

export function convertAmountOperator(operator) {
  switch (operator) {
    case "<":
      return 2;
    case "<=":
      return 4;
    case ">":
      return 3;
    case ">=":
      return 5;
    default:
      return 1;
  }
}

export function printData() {
  const prtHtml = document.getElementById("printMe").innerHTML;

  // Get all stylesheets HTML
  let stylesHtml = "";
  for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
    stylesHtml += node.outerHTML;
  }

  // Open the print window
  const WinPrint = window.open(
    "",
    "",
    "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
  );

  WinPrint.document.write(`<!DOCTYPE html>
  <html>
    <head>
      ${stylesHtml}

      <style>
      .font-size-print {font-size: 20px !important;}
      .font-size-name{ font-size: 20px !important;}
       .font-size-warranty{ font-size: 26px !important;}
       </style>
    </head>
    <body`);
  WinPrint.document.write(`${prtHtml}

    </body>
  </html>`);

  WinPrint.document.close();
  WinPrint.focus();
  setTimeout(function() {
    WinPrint.print();
  }, 500);
}

export function removeOptions(items, item) {
  const index = items.findIndex((prop) => prop.id === item.id);
  if (index > -1) {
    items[index].checked = false;
  }
  return items;
}

export function selectAllOptions(items, selectItems, option) {
  if (option.id === -1) {
    selectItems = [];
    items.map((prop) => {
      prop.checked = true;
      selectItems.push(prop);
      return prop;
    });
  } else {
    items = selectOptions(items, option);

    const itemsChecked = items.filter((prop) => prop.checked);
    const itemNoSelectedAll = items.filter((prop) => prop.id !== -1);

    const index = items.findIndex((prop) => prop.id === -1);

    if (itemsChecked.length === itemNoSelectedAll.length && index > -1) {
      items[index].checked = true;
    } else if (itemsChecked.length !== itemNoSelectedAll.length && index > -1) {
      items[index].checked = false;
      selectItems.splice(index, 1);
    }
    selectItems.push(option);

    selectItems = [];
    items.map((prop) => {
      if (prop.checked) {
        selectItems.push(prop);
      }
    });
  }
  return {
    items,
    selectItems,
  };
}

export function removeSelectAllOptions(items, selectItems, option) {
  if (option.id === -1) {
    items.map((prop) => {
      prop.checked = false;
      return prop;
    });
    selectItems = [];
  } else {
    const removed = [-1, option.id].filter((id) => id);

    removed.map((id) => {
      items.map((prop) => {
        if (prop.id === id) {
          prop.checked = false;
        }
        return prop;
      });
    });
    selectItems = [];

    items.map((prop) => {
      if (prop.checked === true) {
        selectItems.push(prop);
      }
      return prop;
    });
    console.log("items", items);
  }

  return {
    items,
    selectItems,
  };
}

export function selectOptions(items, item) {
  const index = items.findIndex((prop) => prop.id === item.id);
  if (index > -1) {
    items[index].checked = true;
  }
  return items;
}

export function delay(time = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export function formatPrice(value) {
  return new Intl.NumberFormat("vi-VN").format(value);
}

function getChildCategories(category, listCate) {
  return listCate
    .filter((item) => item.level > category.level)
    .filter((item) => item.parentId === category.id);
}

export function getDescendantCategories(category, listCate) {
  const result = [];

  const childs = getChildCategories(category, listCate);
  if (!childs.length) {
    return [];
  }

  childs.forEach((item) => {
    result.push(item);
    result.push(...getDescendantCategories(item, listCate));
  });

  return result;
}

export function getDeepByDescendant(category, descendant) {
  if (!descendant.length) {
    return 1;
  }

  const maxLevel = descendant.reduce((acc, curr) => {
    if (curr.level > acc) {
      return curr.level;
    }
    return acc;
  }, 0);

  return maxLevel - category.level + 1;
}

export function getValueByKey(objectBase, objectData) {
  if (!objectBase || !objectData) return {};

  const term = {};
  for (const key of Object.keys(objectBase)) {
    term[key] = objectData[key];
  }

  return term;
}

export function checkJobTitle() {
  return {
    isCR: (jobTitleId) => {
      return ENUM.JOB_TITLE_ORDER.CR.includes(jobTitleId);
    },
    isShop: (jobTitleId) => {
      return ENUM.JOB_TITLE_ORDER.SHOP.includes(jobTitleId);
    },
    isDP: (jobTitleId) => {
      return ENUM.JOB_TITLE_ORDER.DP.includes(jobTitleId);
    },
  };
}

export function getEmployeeFromLocal() {
  const userFromLocal = SaveDataUtil.getData("employee_info");
  if (userFromLocal) {
    const tmpInfo = JSON.parse(userFromLocal);
    return tmpInfo;
  }
}

export function reduceDataTreeSelect(items, key, value) {
  return items.reduce((result, element) => {
    if (element[key] !== null) {
      result.push({
        id: element[key],
        label: element[value],
        custom: removeAccents(element[value]),
        customLabel: element[value],
      });
    }
    return result;
  }, []);
}

export function cloneDeep(item) {
  if (item) {
    return JSON.parse(JSON.stringify(item));
  }
  return item;
}

export function copyClipBoardOldBrowser(text, elementId, message) {
  if (typeof navigator.clipboard == "undefined") {
    const textArea = document.createElement("textarea");
    const element = document.getElementById(elementId);

    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    element.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");

      makeToastSuccess(`Đã copy ${message} ${text}`);
    } catch (err) {
      makeToastFaile(`Không copy được ${message}`);
    }
    element.removeChild(textArea);
    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {
      makeToastSuccess(`Đã copy ${message} ${text}`);
    },
    () => {
      makeToastFaile(`Không copy được ${message}`);
    }
  );
}

export function copyClipBoard(text, message) {
  navigator.clipboard.writeText(text).then(
    () => {
      makeToastSuccess(`Đã copy ${message} ${text}`);
    },
    () => {
      makeToastFaile(`Không copy được ${message}`);
    }
  );
}

export function checkGroupOrderStatus() {
  return {
    checkStock: (status) => {
      return [
        ENUM.ORDER_STATUS.PREPARED,
        ENUM.ORDER_STATUS.DELIVERY,
        ENUM.ORDER_STATUS.SUCCESS,
      ].includes(status);
    },
    checkImei: (status) => {
      return [
        ENUM.ORDER_STATUS.PREPARED,
        ENUM.ORDER_STATUS.DELIVERY,
        ENUM.ORDER_STATUS.SUCCESS,
      ].includes(status);
    },
    cancel: (status) => {
      return [
        ENUM.ORDER_STATUS.SYSTEM_CANCEL,
        ENUM.ORDER_STATUS.UN_SUCCESS,
        ENUM.ORDER_STATUS.CR_CANCEL,
      ].includes(status);
    },
    withinHoldingStock: (status) => {
      return [ENUM.ORDER_STATUS.PREPARED, ENUM.ORDER_STATUS.DELIVERY].includes(status);
    },
    getByGroup: (status) => {
      const groupA = [
        ENUM.ORDER_STATUS.NEW,
        ENUM.ORDER_STATUS.CONFIRMING,
        ENUM.ORDER_STATUS.PACKING,
        ENUM.ORDER_STATUS.CONFIRMED,
        ENUM.ORDER_STATUS.TRACKING,
        ENUM.ORDER_STATUS.NO_CATCHED_PHONE,
      ];
      const groupB = [
        ENUM.ORDER_STATUS.PACKING,
        ENUM.ORDER_STATUS.CONFIRMED,
        ENUM.ORDER_STATUS.DELIVERY,
        ENUM.ORDER_STATUS.PREPARED,
      ];
      const groupC = [ENUM.ORDER_STATUS.SUCCESS];
      const groupD = [ENUM.ORDER_STATUS.REFUNDED];
      const groupE = [ENUM.ORDER_STATUS.CR_CANCEL, ENUM.ORDER_STATUS.UN_SUCCESS];

      let statusNumber = [];
      if (groupA.includes(status)) {
        statusNumber.push(1);
      }
      if (groupB.includes(status)) {
        statusNumber.push(2);
      }
      if (groupC.includes(status)) {
        statusNumber.push(3);
      }
      if (groupD.includes(status)) {
        statusNumber.push(4);
      }
      if (groupE.includes(status)) {
        statusNumber.push(5);
      }

      if (!statusNumber.length) {
        throw new Error("Trạng thái không đúng");
      }

      return statusNumber;
    },
    valid: (sourceGroup, targetGroup) => {
      const targetMaxStatus = Math.max(...targetGroup);
      const sourceMaxStatus = Math.max(...sourceGroup);

      if (sourceMaxStatus <= targetMaxStatus) {
        return true;
      }

      if (sourceGroup.includes(targetMaxStatus)) {
        return true;
      } else {
        return false;
      }
    },
    validToBill: (status) => {
      return [
        ENUM.ORDER_STATUS.CONFIRMED,
        ENUM.ORDER_STATUS.PACKING,
        ENUM.ORDER_STATUS.PREPARED,
      ].includes(status);
    },
  };
}

export function checkedOptions(items, option, prop, value) {
  const index = items.findIndex((item) => item[prop] === option[prop]);
  if (index > -1) {
    items[index].checked = value;
  }
  return items;
}

export function calculateDiscountAmount(discount, type, money, quantity = 1) {
  let amount = 0;
  const PERCENT_100 = 100;
  // percent default discount by 1 product or item
  if (type === ENUM.AMOUNT_TYPE.PERCENT) {
    amount = (money / quantity) * (discount / PERCENT_100);
  } else {
    amount = discount / quantity;
  }
  return amount;
}

export function getValuesEnum(obj) {
  return Object.values(obj);
}

export function isValidEmail(string) {
  const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  return emailRegExp.test(string);
}

export function convertArrayToObject(array, key) {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
}
export function getPropertyValue(object, keyPath) {
  keyPath = keyPath.replace(/\[(\w+)\]/g, ".$1");
  keyPath = keyPath.replace(/^\./, "");
  const a = keyPath.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (k in object) {
      object = object[k];
    } else {
      return;
    }
  }
  return object;
}

export function isValidMinQuantity(items) {
  let isValidMinQuantity = true;
  items.map((item) => {
    if (!item.$v.productItem.quantity.minValue) {
      isValidMinQuantity = false;
    }
  });

  return isValidMinQuantity;
}

export function isEmptyAccountingAccount(enteredAmount, enteredAccountingAccount) {
  return !(unMaskPrice(enteredAmount) > 0 && enteredAccountingAccount.trim() === "");
}

export function truncateText(text, MAX_STRING = 15) {
  if (!text || !text.length) {
    return "";
  }
  return text.length < MAX_STRING ? text : text.substring(0, MAX_STRING) + "...";
}
