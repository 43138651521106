import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { get, uniq } from "lodash";

import { getData } from "@/utils/saveDataToLocal";
import { makeToastFaile } from "@/utils/common";
import { ERROR_CODE } from "@/utils/enum";

import JwtService from "@/core/services/jwt.service";
import { LOGOUT } from "@/core/services/store/auth.module";
import { getServer } from "@/core/config/server";

import router from "@/router";
import store from "@/core/services/store";

/**
 * Service to call HTTP request via Axios
 */
let errorMessages = [];
const SERVER = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
  DEMO: "demo",
  DEMO2APP: "demo2app",
  TEST: "test",
  TEST_INTERN: "test-intern",
  TEST2APP: "test2app",
};
const server = getServer(SERVER.PRODUCTION);
const READ_ONLY_BACKEND = server.read;
const READ_AND_WRITE_BACKEND = server.write;

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = READ_ONLY_BACKEND;
    Vue.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        switch (get(err, "response.status")) {
          case 401:
            store.dispatch(LOGOUT);
            router.push({ name: "login" });
            return Promise.reject(err);
          case 900:
            errorMessages.push(get(err, "response.data.message"));
            setTimeout(() => {
              errorMessages = uniq(errorMessages);
              errorMessages.map((message) => {
                makeToastFaile(message);
              });
              errorMessages = [];
            }, 2000);
            return Promise.reject(err);
          default:
            return Promise.reject(err);
        }
      }
    );

    // Auto set token to request.
    Vue.axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${JwtService.getToken()}`;
      config.headers.version = server.version;
      config.headers.merchantId = getData("merchantId");
      return config;
    });
  },

  handleError(error) {
    const errorCode = get(error, "response.status");
    switch (errorCode) {
      case ERROR_CODE.UNAUTHORIZED: {
        store.dispatch(LOGOUT);
        router.push({ name: "login" });
        return Promise.reject(error);
      }
      case ERROR_CODE.ERROR_VERSION: {
        const message = get(error, "response.data.message");
        errorMessages.push(message);
        setTimeout(() => {
          errorMessages = uniq(errorMessages);
          errorMessages.map((message) => {
            makeToastFaile(message);
          });
          errorMessages = [];
        }, 2000);
        return Promise.reject(error);
      }
      default:
        return Promise.reject(error);
    }
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    return axios.get(`${resource}`);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      baseURL: READ_AND_WRITE_BACKEND,
    });
  },

  /**
   * Set the PATCH HTTP request
   * @param resource
   * @returns {*}
   */
  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params, {
      baseURL: READ_AND_WRITE_BACKEND,
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params, {
      baseURL: READ_AND_WRITE_BACKEND,
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios
      .delete(resource, {
        baseURL: READ_AND_WRITE_BACKEND,
      })
      .catch((error) => {
        if (error.response) {
          makeToastFaile(
            error.response.data
              ? error.response.data.message
              : "Có vấn đề xảy ra hãy thử lại"
          );
        } else {
          makeToastFaile(error.message || "Có vấn đề xảy ra hãy thử lại");
        }
        return error;
      });
  },

  /**
   * Send the POST HTTP request
   * @param resource
   * @returns {*}
   */
  download(url, body, options) {
    return axios.post(url, body, options).catch((error) => {
      if (error.response) {
        makeToastFaile(
          error.response.data
            ? error.response.data.message
            : "Có vấn đề xảy ra hãy thử lại"
        );
      } else {
        makeToastFaile(error.message || "Có vấn đề xảy ra hãy thử lại");
      }
      return error;
    });
  },

  getServerInformation() {
    return server;
  },
};

export default ApiService;
