export const LOADING_NONE = "loadingNone";
const LOADING_SETTING_01 = {
  style: {
    background: "black transparent",
    zIndex: 1051,
  },
  delay: 500,
};

export const loadingConfig = {
  get: {
    ["/verify"]: LOADING_NONE,
    ["/room/getList"]: LOADING_NONE,
    ["/room"]: LOADING_NONE,
    ["/meeting/list-of-user-in-the-meeting"]: LOADING_NONE,
    ["/getUserByDepartment"]: LOADING_NONE,
    ["/meeting/listOfUserInTheMeeting"]: LOADING_NONE,
  },
  post: {
    ["/meeting/getBookingInfoByRoomIDAndDate"]: LOADING_NONE,
    ["/meeting/bookingRoom"]: LOADING_SETTING_01,
    ["/newMeetingNotify"]: LOADING_NONE,
    ["/bookinginfo/saveUserToParticipant"]: LOADING_NONE,
    ["/meeting/recurringMeetingValidation"]: LOADING_SETTING_01,
    ["/getUserByDepartment"]: LOADING_NONE,
    ["/bookinginfo/sendEmail"]: LOADING_SETTING_01,
    ["/bookinginfo/checkSendEmail"]: LOADING_NONE,
  },
  put: {
    ["/meeting/cancelBooking"]: LOADING_SETTING_01,
  },
};
