import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const SET_DEPARTMENT_LIST = "setDepartmentList";
export const RELOAD_COUNT = "reloadCount";
export const LOADING_TRUE = "loadingTrue";
export const LOADING_FALSE = "loadingFalse";
export const LOADING_RESET = "loadingReset";

const state = {
  departmentList: [],
  reloadCount: 0,
  isLoading: 0,
  loaderStyle: {
    background: "black transparent",
    zIndex: 1020,
  },
};

const getters = {
  departmentList(state) {
    return state.departmentList;
  },
  reloadCount(state) {
    return state.reloadCount;
  },
  isLoading(state) {
    return state.isLoading;
  },
  loaderStyle(state) {
    return state.loaderStyle;
  },
};

const mutations = {
  [SET_DEPARTMENT_LIST](state, data) {
    state.departmentList = data;
  },
  [RELOAD_COUNT](state) {
    state.reloadCount++;
  },
  [LOADING_TRUE](state, style) {
    state.isLoading++;
    state.loaderStyle = style;
    setTimeout(() => {
      state.isLoading = 0;
    }, 10000);
  },
  [LOADING_FALSE](state, delay) {
    const reduceLoadingTime = () => {
      --state.isLoading;
      if (state.isLoading < 0) state.isLoading = 0;
    };

    if (typeof delay === "number" && delay > 0) {
      setTimeout(reduceLoadingTime, delay);
    } else {
      reduceLoadingTime();
    }
  },
  [LOADING_RESET](state) {
    state.isLoading = 0;
  },
};

const actions = {
  [SET_DEPARTMENT_LIST](context, data) {
    context.commit(SET_DEPARTMENT_LIST, data);
  },
  [RELOAD_COUNT](context, data) {
    context.commit(RELOAD_COUNT, data);
  },
  [LOADING_TRUE](context, data) {
    context.commit(LOADING_TRUE, data);
  },
  [LOADING_FALSE](context, data) {
    context.commit(LOADING_FALSE, data);
  },
  [LOADING_RESET](context, data) {
    context.commit(LOADING_RESET, data);
  },
};

export default { state, mutations, actions, getters };
