module.exports.ERROR_CODE = Object.freeze({
  BAD: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  ERROR_INTERNAL: 500,
  ERROR_VERSION: 900,
});

module.exports.BILL_ITEM_TYPE = Object.freeze({
  PRODUCT: 1, // sản phẩm
  PRODUCT_BONUS: 2, // sản phẩm tặng kèm
  PRODUCT_PROMTION: 3, // sản phẩm bán kèm
  RETURN_WARRANTY: 4, // sản phẩm trả bảo hành
});

module.exports.CONTACT_TYPE = Object.freeze({
  Customer: '1',
  Provider: '2',
  Installment: '3',
  Employee: '4',
  Credit: '5',
  ShiftWork: '6',
  Ecommerce: '21',
  Other: '20',
  ShipFee: '22',
});

module.exports.TRANSACTION_TYPE = Object.freeze({
  CashIn: '1', // Phiếu thu
  CashOut: '2', // Phiếu chi
  BankIn: '3', // Báo có (nộp tiền)
  BankOut: '4', // Báo nợ (Rút tiền)
  StockImport: '8', // Phiếu nhập
  GoodsIssue: '9', // Phiếu xuất hàng
  Sale: '6', // Phiếu bán hàng
  MerchandiseReturn: '5', // Phiếu bán hàng
});

module.exports.STOCK_SLIP_TYPE = Object.freeze({
  IMPORT_PROVIDER: 1, // nhập kho NCC
  EXPORT_PROVIDER: 2, // xuất kho NCC
  TRANSFER_STOCK: 3, // chuyen kho
  REQUIRE_IMPORT_STOCK: 4, // yeu cau nhap chuyen kho
  REQUIRE_EXPORT_STOCK: 5, // yeu cau xuat chuyen kho
  EXPORT_STOCK_TO_SALE: 6, // xuat kho ban le
  EXPORT_STOCK_TO_SALE_WHOLSE: 7, // xuat kho ban buon
  IMPORT_STOCK_FROM_BILL: 8, // nhap kho tu phieu tra hang
  IMPORT_STOCK_FROM_ORDER: 9, // nhap kho tu don chuyen hoan
  EXPORT_TO_SHIPPING: 10, // xuat kho giao hang
  IMPORT_FROM_TRADE_IN: 11, // nhap kho tu phiếu thu cũ
  TRANSFER_TO_NHANH: 12, // chuyen kho sang he thong cu
  REQUIRE_EXPORT_STOCK_TO_NHANH: 13, // phiếu yêu cầu xuất chuyển kho sang hệ thống cũ
  EXPORT_TRANSFER_TO_NHANH: 14, // xuat chuyen kho sang he thong cu
  IMPORT_WARRANTY: 15, // nhập kho bảo hành
  EXPORT_WARRANTY: 16, // xuất kho bảo hành
  IMPORT_HOLDING: 17, // nhập giữ hàng
  EXPORT_HOLDING: 18, // xuất trả giữ hàng
  TRANSFER_WARRANTY_STOCK: 19, // chuyển kho bảo hành
  REQUIRE_EXPORT_WARRANTY_STOCK: 20, // yeu cau xuat chuyen kho bao hanh
  IMPORT_STOCK_FROM_WARRANTY_BILL: 22, // nhap kho tu phieu bao hanh
  IMPORT_WARRANTY_FROM_BILL: 23, // nhập kho bảo hành từ hóa đơn bảo hành
  EXPORT_WARRANTY_FROM_BILL: 24, // xuất kho bảo hành từ hóa đơn bảo hành
});

module.exports.AMOUNT_TYPE = Object.freeze({
  MONEY: 1, // tiền mặt
  PERCENT: 2, // theo %
});

module.exports.PRODUCT_TYPE = Object.freeze({
  PRODUCT: 1, // sản phẩm
  PRODUCT_IMEI: 2, // sản phẩm IMEI
  PRODUCT_COMBO: 3, // sản phẩm combo
  PRODUCT_SERVICE: 4, // sản phẩm dịch vụ
});

module.exports.STOCK_SLIP_STATUS = Object.freeze({
  NEW: 1, // mới
  APPROVED: 2, // đã duyệt
  CONFIRMED: 3, // đã xn
});

module.exports.TYPE_ORDER = Object.freeze({
  SHOP: 1, // Mua tại quầy
  PREORDER: 2, // Đặt trước
  SHIPPING: 3, // Chuyển hàng
  EX_SHIPPING: 4, // Hãng vận chuyển
  WEBSITE: 5, // Website
});

module.exports.ITEM_TYPE = Object.freeze({
  MAIN: 1,
  GIFT: 2,
  ATTACH: 3,
});

module.exports.ORDER_STATUS = Object.freeze({
  NEW: 1, // Mới
  CONFIRMING: 2, // Đang xử lí
  CONFIRMED: 3, // Đã xác nhận
  DELIVERY: 4, // Đang giao
  PACKING: 5, // Đang đóng gói
  SUCCESS: 6, // Thành công (Tồn)
  UN_SUCCESS: 7, // Không thành công
  SYSTEM_CANCEL: 8, // Hệ thống hủy
  REFUNDED: 9, // Đã chuyển hoàn
  NO_CATCHED_PHONE: 10, // Không bắt máy
  CR_CANCEL: 11, // CR huỷ
  //TODO xoá sau khi stable
  DEPOSIT: 12, // Đã đặt cọc
  AGREE_DEPOSIT: 13, // Đồng ý cọc
  // end TODO
  PREPARED: 14, // Đã chuẩn bị (Tồn)
  TRACKING: 15, // Đang theo dõi
});

module.exports.CRITERIA_FILTER_BILL = Object.freeze({
  HAVE_DISCOUNT: 1, // Có tiền chiết khấu
  NO_DISCOUNT: 2, //Không có tiền chiết khấu
  HAVE_TRANSFER: 3, // Có tiền chuyển khoản
  HAVE_CREDIT: 4, // Có thanh toán quẹt thẻ
  HAVE_INSTALLMENT: 5, // Có thanh toán trả góp
  USE_POINT: 6, // Có dùng tiền tích điểm
  HAVE_DEBT: 7, // Có công nợ
  STILL_OWE: 8, // Còn công nợ
  OUT_OF_DEBT: 9, // Hết công nợ
  HAVE_CASH: 10, // Có tiền mặt
  FROM_ORDER: 11, // Hóa đơn từ đơn hàng
  HAVE_GIFT: 12, //Có quà tặng
  HAVE_DEPOSIT: 13, // có cọc từ đơn hàng
  UNDERPAYMENT: 14, // còn thiếu tiền
  UNPAID: 15, // chưa thanh toán
  NO_CODESHIP: 16, // chưa gắn code ship
  USE_COUPON: 17,
});

module.exports.PURCHASE_ORDER_STATUS = Object.freeze({
  NEW: 1, // Mới
  PURCHASE: 2, // Ngành hàng
  ACCOUNTANT: 3, // Kế toán
  PO_HEAD_CANCEL: 4, // trưởng ngành hàng hủy phiếu
  ACCOUNTANT_CANCEL: 5, // kế toán hủy phiếu
  READY: 6, // sẵn sàng để duyệt
  CANCELED: 7, // hủy yêu cầu
});

module.exports.PURCHASE_ORDER_APPROVE_MODE = Object.freeze({
  PURCHASE: 'purchase',
  ACCOUNTANT: 'accountant',
});

module.exports.PURCHASE_ORDER_TYPE = Object.freeze({
  IMPORT: 1,
  CONSIGNMENT: 2,
});

module.exports.LOAN_APPLICATION_STATUS = Object.freeze({
  INIT: 1,
  SUBMITED: 2,
  APPROVED: 3,
  REJECTED: 4,
  DISBURSED: 5,
  OP_PROCESSING: 6,
});

module.exports.UPLOAD_ENTITY = Object.freeze({
  BILLS: 'bills',
  BILLS_INVOICE: 'bills-invoices',
  PO: 'purchase-orders',
  STOCK_SLIP: 'stock-slips',
  TRANSACTIONS: 'transactions',
  ORDERS: 'orders',
});

module.exports.CUSTOMER_TYPE = Object.freeze({
  RETAIL_CUSTOMER: 1, // khách lẻ
  WHOLESALE_CUSTOMER: 2, // khách buôn
  AGENCY: 3, // đại lý
  VIP: 4, // vip
  KOL: 5, // kol
});

module.exports.PRODUCT_SEARCH_TYPE = Object.freeze({
  PRODUCT_NAME: 1, // tìm theo tên, mã, mã vạch
  IMEI_CODE: 2, // tìm theo mã IMEI
  FLEXIBLE_COMBO: 3,
});

module.exports.FILE_UPLOAD_STATUS = Object.freeze({
  NEW: 1, // Mới
  EXIST: 2, // Tồn tại
});

module.exports.FILE_UPLOAD_MODE = Object.freeze({
  INITIAL: 0, // Mới
  SAVING: 1, // Tồn tại
  SUCCESS: 2, // Tồn tại
  FAILED: 3, // Tồn tại
});

module.exports.JOB_TITLE = Object.freeze({
  GD: 1, // Giám đốc
  RSM: 2, // Quản lý vùng
  ASM: 3, // Quản lý khu vực
  QL: 4, // Quản lý cửa hàng
  TL: 5, // Trợ lý cửa hàng
  KTBH: 6, // Kế toán bán hàng
  TVBH: 7, // Tư vấn bán hàng
  KTPM: 8, // Hỗ trợ kỹ thuật
  TDKH: 9, // Tiếp đón khách hàng
  KTPC: 10, // Kỹ thuật phần cứng
  GDNHPK: 11, // Giám đốc ngành hàng phụ kiện
  NVNHPK: 12, // NV phụ trách ngành hàng PK
  ADMIN_NHPK: 13, // Admin ngành hàng phụ kiện
  CDPK: 14, // nhân viên chuyên doanh phụ kiện
  QL_CSKH: 15, // quản lý CR
  CSKH: 16, // nhân viên CR
  CTV_CSKH: 17, // cộng tác viên CR
  ADMIN_GDNHPK: 18, // Admin giám đốc ngành hàng phụ kiện
  KTNB: 19, // Kế toán nội bộ
  QL_DP: 20, // Trưởng bộ phận điều phối
  MKT: 21, // Nhân viên marketing
  QL_MKT: 22, // Trưởng marketing
  DP: 23, // Điều phối
  QL_KTNB: 24, // Trưởng kế toán nội bộ
});

module.exports.JOB_TITLE_ORDER = Object.freeze({
  CR: [this.JOB_TITLE.QL_CSKH, this.JOB_TITLE.CSKH, this.JOB_TITLE.CTV_CSKH],
  SHOP: [
    this.JOB_TITLE.ASM,
    this.JOB_TITLE.QL,
    this.JOB_TITLE.TL,
    this.JOB_TITLE.KTBH,
    this.JOB_TITLE.TVBH,
    this.JOB_TITLE.KTPM,
    this.JOB_TITLE.TDKH,
    this.JOB_TITLE.KTPC,
  ],
  DP: [this.JOB_TITLE.DP, this.JOB_TITLE.QL_DP],
});

module.exports.PARTNER_PAYMENT = Object.freeze({
  SUCCESS: 1, // Thành công
  FAILED: 2, // Thất bại
  AGREE_DEPOSIT: 3, // Đồng ý cọc
  DEPOSIT: 4, // Đã đặt cọc
});

module.exports.HISTORY_TYPE = Object.freeze({
  ORDER: 1, // đơn hàng
  BILL: 2, // bán hàng
  PRODUCT: 3, // sản phẩm
  PRODUCT_IMEI: 4, // sản phẩm IMEI
  PRE_ORDER: 5, // đơn đặt trước
  STOCK_SLIP: 6, // phiếu kho
});

module.exports.HISTORY_ACTION_TYPE = Object.freeze({
  UPDATE: 1, // cập nhật
  DELETE: 2, // xóa
  CREATE: 3, // tạo mới
});

module.exports.MODAL_TYPE_UPSERT_ORDER = Object.freeze({
  GIFT: 'gift',
  ATTACH: 'attach',
  PRODUCT_IMEI: 'note',
  CHANGE_GIFT: 'change-gift',
  CHANGE_MAIN: 'change-main',
});

module.exports.PAYMENT_TYPE = Object.freeze({
  CASH: 1,
  TRANSFER: 2,
  CREDIT: 3,
  INSTALLMENT: 4,
  CREDIT_FEE: 5,
  REFUND: 6,
});

module.exports.BILL_TYPE = Object.freeze({
  RETAIL_INVOICE: 1, // hóa đơn bán lẻ
  WHOLESALE_INVOICE: 2, // hóa đơn bán buôn
  REFUND_INVOICE: 3, // trả hàng
  SHIPPING: 4, // hóa đơn giao hàng
  IMPORT_STOCK_FROM_TRANSFER: 5, // enum này dùng cho phiếu nhập kho từ phiếu chuyển kho
  WARRANTY: 6, // hóa đơn bảo hành
});
module.exports.WARRANTY_BILL_STATUS = Object.freeze({
  NEW: 1, // mới
  PROCESSING: 2, // đang xử lý
  DONE: 3, // đã trả bảo hành
  TRANSFER_STOCK: 4, // đang chuyển kho BH
});
module.exports.WARRANTY_REASON_STATUS = Object.freeze({
  ACTIVE: 0, // hoạt động
  INACTIVE: 1, // không hoạt động
});
module.exports.WARRANTY_REASON_GROUP_STATUS = Object.freeze({
  ACTIVE: 0, // hoạt động
  INACTIVE: 1, // không hoạt động
});
module.exports.PO_RELATION_TYPE = Object.freeze({
  INDEPENDENT: 1,
  PARENT: 2,
  CHILD: 3,
});
module.exports.BILL_VAT_TYPE = Object.freeze({
  PERSONAL: 1,
  COMPANY: 2,
});

module.exports.VAT_INVOICE_STATUS = Object.freeze({
  NEW: 1,
  PROCESSING: 2,
  PUBLIC: 3,
  CUSTOMER_SENT: 4,
  REFUND: 5,
});

module.exports.VAT_SYNC_STATUS = Object.freeze({
  NEW: 1,
  SENT_FAIL: 2,
  SENT_SUCCESS: 3,
  PUBLICH: 4,
  CANCELED: 5,
});

module.exports.VAT_INVOICE_PUSH = Object.freeze({
  UN_APPROVED: 1,
  APPROVED: 2,
  SUBMITED: 3,
});

module.exports.VAT_AMOUNT = Object.freeze({
  PERCENT_0: 0,
  PERCENT_8: 8,
  PERCENT_10: 10,
  PERCENT_KCT: -1,
});

module.exports.TRANSFER_STORE_FORM_STATUS = Object.freeze({
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  APROVED_TRANSFER: 4,
});

module.exports.JOB_TITLE_SHOW_BY_OWNER = Object.freeze([
  this.JOB_TITLE.QL,
  this.JOB_TITLE.TL,
]);

module.exports.JOB_TITLE_APPROVE = Object.freeze([
  this.JOB_TITLE.ASM,
  this.JOB_TITLE.RSM,
  this.JOB_TITLE.GD,
]);

module.exports.ENVIRONMENT = Object.freeze({
  BETA_FEATURE_STORE_ID: 88036,
});

module.exports.STORE_TYPE = Object.freeze({
  DDV: 1,
  VDD: 2,
  MBF: 3,
  DP: 4,
});

module.exports.VAT_SYNC_STATUS = Object.freeze({
  NEW: 1,
  SENT_FAIL: 2,
  SENT_SUCCESS: 3,
  PUBLICH: 4,
  CANCELED: 5,
});

module.exports.PARTNER_API_STATUS = Object.freeze({
  ACTIVE: 1,
  INACTIVE: 2,
});

module.exports.PARTNER_API_DETAIL_TYPE = Object.freeze({
  PRODUCT: 1,
  STORE: 2,
  CATEGORY: 3,
});

module.exports.PAYMENT_STATUS = Object.freeze({
  SUCCESS: 1,
  FAILED: 2,
});

module.exports.REFERENCE_TYPE = Object.freeze({
  BILL: 1,
  ORDER: 2,
});

module.exports.DELETE_TYPE = Object.freeze({
  AVAILABLE: 0,
  DELETED: 1,
});

module.exports.COUPON_STATUS = Object.freeze({
  ACTIVATE: 1,
  INACTIVATE: 0,
});
